import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

registerApplication({
  name: "@svelte-react-vue-angular-spa/angular-cw",
  app: System.import("@svelte-react-vue-angular-spa/angular-cw"),
  activeWhen: ["/"],
});

// const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
// layoutEngine.activate();

function mountScene() {
  console.log("scene mount in angular div");
  var scene = document.getElementById("renderCanvas");
  var sceneTag = document.getElementById("main-scene-3D")
  sceneTag.appendChild(scene);
};
function unMountScene() {
  console.log("scene unmount in angular div");
  var scene = document.getElementById("renderCanvas");
  var sceneTag = document.getElementById("root3dScene")
  sceneTag.appendChild(scene);
};

function mountSceneIntervall(){
  try {
    mountScene()
  } catch (error) {
    setTimeout(() => {
      mountSceneIntervall()
    }, 1000 );
  }
};
function unMountSceneIntervall(){
  try {
    unMountScene()
  } catch (error) {
    setTimeout(() => {
      unMountSceneIntervall()
    }, 1000 );
  }
};

window.addEventListener("transfer-scene-inner",  evt  => {
  console.log("scene in angular div" , evt );
  if( evt.detail == 'start'){
    mountSceneIntervall()
  }else{
    unMountSceneIntervall()
  }
  
});

start();
